@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  /* font-family: "ManropeRegular"; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* @font-face {
  font-family: 'ManropeLight';
  src: local('ManropeLight'), url('../public/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeMedium';
  src: local('ManropeMedium'),url('../public/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeRegular';
  src: local('ManropeRegular'),url('../public/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
} */
/* @font-face {
  font-family: 'ManropeSemiBold';
  src: local('ManropeSemiBold'),url('../public/fonts/Roboto/Roboto') format('truetype');
} */
/* @font-face {
  font-family: 'ManropeBold';
  src: local('ManropeBold'),url('../public/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeExtraBold';
  src: local('ManropeExtraBold'),url('../public/fonts/Roboto/Roboto-Black.ttf') format('truetype');
} */

@font-face {
  font-family: 'InterSemiBold';
  src: local('InterSemiBold'),url('../public/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'InterBold';
  src: local('InterBold'),url('../public/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'),url('../public/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
}


@keyframes slideDown {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
}

@keyframes slideUp {
  0% { transform: translateY(100%); }
  100% { transform: translateY(0); }
}

@keyframes slideUpReverse {
  0% { transform: translateY(0); }
  100% { transform: translateY(-100%); }
}

@keyframes slideDownReverse {
  0% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}

.animate-slideDown {
  animation: slideDown 0.8s ease-out forwards;
}

.animate-slideUp {
  animation: slideUp 0.8s ease-out forwards;
}

.animate-slideUpReverse {
  animation: slideUpReverse 0.5s ease-out forwards;
}

.animate-slideDownReverse {
  animation: slideDownReverse 0.5s ease-out forwards;
}



.ag-theme-custom .ag-header-cell, 
.ag-theme-custom .ag-row {
  cursor: default !important; 
  font-family: inherit !important;

}
.ag-theme-custom .ag-row:hover {
    background-color: inherit !important;
}
.ag-header{
  height: 50px !important;
  min-height: 50px !important;
}

.ag-header-cell {
  color: #6E7C87;
  font-family:"InterRegular";
  font-size: "14px !important";
  
  /* font-weight: 700 !important; */
}

/* .ag-header-border{
  color: #6E7C87;
  font-family:"InterRegular";
  border-right: 1px solid #c7c7c7 !important;
} */

.ag-header-border {
  position: relative;
}

.ag-header-border::after {
  content: '';
  position: absolute;
  right: 0;
  top: 5.2px;   
  bottom: 5.2px; 
  width: 1px; 
  background-color: #e5e9eb;  
}

#sitesGrid .ag-cell {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;

}

/* .ag-theme-quartz{
  border: 1px solid #e5e9eb !important;
} */


.ag-row {
  border-bottom: 1px solid #e5e9ee !important;
}


#sitesGrid .ag-row-even {
 background-color: white;
}
#sitesGrid .ag-header {
  background-color: white; 
} 
#sitesGrid .ag-header-icon {
  display: inline-block !important;
  visibility: visible !important;
}
.ag-cell-focus {
  border: none !important;
}


.ag-cell{
  font-family: "InterRegular" !important;
  color:#40444f !important;
  text-transform: lowercase;
}



.ag-icon-asc{
  color : #a5aab6 !important
}
.ag-icon-desc{
  color : #a5aab6 !important
}

.ag-ltr .ag-sort-indicator-icon{
  display: none !important;
}
#assetsGrid .ag-cell {
  display: flex;
  align-items: center;
  outline: none;
}
#assetsGrid .ag-row-even {
  background-color: white !important;
 }
 #assetsGrid .ag-header {
   background-color: white; 
 } 

 #assetsGrid .ag-header-icon {
  display: none !important;
 }

 #usersGrid .ag-cell {
   display: flex;
   align-items:  center;
   outline: none;
}

#usersGrid .ag-row-even {
  background-color: white !important;
 }
 #usersGrid .ag-header {
   background-color: white; 
 } 
 #usersGrid .ag-header-icon {
  display: none !important;
 }


 #campaignGrid .ag-cell {
  display: flex;
  padding-top: 8px;
  align-items: center;
  outline: none;
}

#campaignGrid .align-start {
  text-transform: inherit;
  align-items: center;
  outline: none;
}

#campaignGrid .ag-row-even {
  background-color: white !important;
 }
 #campaignGrid .ag-header {
   background-color: white; 
 } 
 #campaignGrid .ag-header-icon {
  display: none !important;
 }
 
 #usersSitesGrid .ag-cell {
  display: flex;
  padding-top: 8px;
  align-items: center;
  outline: none;
}

#usersSitesGrid .align-start {
  align-items: center;
}

#usersSitesGrid .ag-row-even {
  background-color: white !important;
 }
 #usersSitesGrid .ag-header {
   background-color: white; 
 } 
 #usersSitesGrid .ag-header-icon {
  display: none !important;
 }

 .ag-header{
  border-top: 1px solid #e5e9eb !important;
 }
 .ag-root-wrapper{
  border-radius: 0 !important;
  border:none !important;
  /* border-top: 1px solid #e5e9eb !important;
  border-bottom: none !important */
}

 #usersGrid .ag-root-wrapper{
  border-radius: 0 !important;
  border:  none;
  /* border-top: 1px solid #e5e9eb !important;
  border-bottom: 1px solid #e5e9eb !important; */
}

 .custom-table-header-padding{
  position: relative;
  padding-left: 20px !important;
 }


.custom-table-header-padding::after {
  content: '';
  position: absolute;
  right: 0;
  top: 5.2px;   
  bottom: 5.2px; 
  width: 1px; 
  background-color: #e5e9eb;  
}



 .my-masonry-display-grid {
  display: -webkit-box; 
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px; 
  width: auto;
}
.my-masonry-display-grid_column {
  padding-left: 20px; 
  background-clip: padding-box;
}


.my-masonry-display-grid_column > div { 
  margin-bottom: 20px;
}

.campaign-masonry-display-grid_column {
  padding-left: 25px; 
  background-clip: padding-box;
}


.campaign-masonry-display-grid_column > div { 
  margin-bottom: 20px;
}


@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Edge */
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* for Firefox */
}

.required-field::before {
  content: "*";
  color: red;
  /* float: right; */
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.input-hover:hover {
  border-color: #3b82f6; /* Tailwind's blue-500 */
}

.input-selected {
  border-color: #3b82f6; /* Tailwind's blue-500 */
  background-color: #e0f2fe; /* Tailwind's blue-100 */
}

.react-datepicker__header{
  background-color: #0e076a !important;
  opacity: .92;
}

.react-datepicker__current-month{
  color: white !important;
}

.react-datepicker__day-name{
  color: white !important;
}

.react-datepicker__triangle{
  fill: #0e076a !important;
  color: #0e076a !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  /* margin-top: 20px; */
  height: 100%;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  padding: 8px 12px;
  border: 1px solid #ddd;
  color: #0E73F6;
  text-decoration: none;
  cursor: pointer;
}

.pagination a:hover {
  background-color: #eee;
}

.pagination .active a {
  /* background-color: #007bff; */
  background-color: #2d77aa;
  color: white;
  border-color: #007bff;
}

.pagination .disabled a {
  color: #ddd;
  cursor: not-allowed;
}


.custom-tooltip {
  position: absolute;
  top: 120%; 
  left: -20px;
  max-width: 380px;
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 9999;
  opacity: 0.9;
}

.custom-tooltip::after {
  content: '';
  position: absolute;
  top: -5px; 
  left: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}


.datepicker-wrapper{
  padding: 12px !important
}